import React, {useMemo, useState} from 'react'

import Dropdown from "react-bootstrap/Dropdown";

const Templates = ({setTemplate, queueName, commonAnswers: {queueTemplates},hideDropdown, heightConversation}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	let listTemplates = queueTemplates[queueName];
	if (queueTemplates['all']) {
		listTemplates = (listTemplates) ? [...listTemplates, ...queueTemplates['all']] : queueTemplates['all'];
	}
	const toggleDropdown = () => setShowDropdown(!showDropdown)

	const getHeightDropdown = useMemo(
		() => heightConversation === 0 ? 'calc(75vh - 90px)' : `calc(${heightConversation}px * 0.75)`,
		[heightConversation]
	)

	return (
		<Dropdown className='templates-toggle' onToggle={toggleDropdown} show={showDropdown && !hideDropdown} drop='up'>
			<Dropdown.Toggle variant="link">
				<div>Templates</div><div className={"dropdown-icon"}/>
			</Dropdown.Toggle>

			<Dropdown.Menu style={{ maxHeight: getHeightDropdown }}>
				{listTemplates &&
				listTemplates.map((item, key) => {
					return (
						<Dropdown.Item
							key={key}
							eventKey={item.text}
							onClick={() => setTemplate(item)}>
							<span className={item.underline?"underline-bold":""}>{key+1}. {item.title}</span>
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Templates;
